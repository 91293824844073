import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91name_937x63acQ0ysMeta } from "/vercel/path0/pages/[destination]/[name].vue?macro=true";
import { default as _91filter_93GPpiwq3kq2Meta } from "/vercel/path0/pages/[destination]/[slug]/[filter].vue?macro=true";
import { default as addedValueW3C07SxunVMeta } from "/vercel/path0/pages/addedValue.vue?macro=true";
import { default as bonderh3L3YrcmNxMeta } from "/vercel/path0/pages/bonder.vue?macro=true";
import { default as contactJnsDpYVejzMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as customHolidays9oLJY2tFCwMeta } from "/vercel/path0/pages/customHolidays.vue?macro=true";
import { default as indexuedj0fLb5OMeta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as _91id_93dYAHH5YvFdMeta } from "/vercel/path0/pages/feedback/[id].vue?macro=true";
import { default as _91slug_93Gxm7AbZHzHMeta } from "/vercel/path0/pages/house/[slug].vue?macro=true";
import { default as photosYLJuhHW2nGMeta } from "/vercel/path0/pages/house/photos.vue?macro=true";
import { default as error0cLyEglmoSMeta } from "/vercel/path0/pages/house/preorder/error.vue?macro=true";
import { default as indexshYkREaB9sMeta } from "/vercel/path0/pages/house/preorder/index.vue?macro=true";
import { default as successUZjHTFPrCJMeta } from "/vercel/path0/pages/house/preorder/success.vue?macro=true";
import { default as _91slug_93tzQpSEDkoLMeta } from "/vercel/path0/pages/house/transaction/[slug].vue?macro=true";
import { default as photosJFQ0GlQEisMeta } from "/vercel/path0/pages/house/transaction/photos.vue?macro=true";
import { default as houseCollectionsgdvSuKRBWMeta } from "/vercel/path0/pages/houseCollection.vue?macro=true";
import { default as clientConditions18ISITVpyqMeta } from "/vercel/path0/pages/legal/clientConditions.vue?macro=true";
import { default as ownerConditionsMjyX8BC8W6Meta } from "/vercel/path0/pages/legal/ownerConditions.vue?macro=true";
import { default as privacyPolicyPiYoJAovnfMeta } from "/vercel/path0/pages/legal/privacyPolicy.vue?macro=true";
import { default as termsOfUseKsPhILLrdfMeta } from "/vercel/path0/pages/legal/termsOfUse.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as bookingsMnzJuiEuOIMeta } from "/vercel/path0/pages/myaccount/bookings.vue?macro=true";
import { default as destinationAlert6vJWuUrHLGMeta } from "/vercel/path0/pages/myaccount/destinationAlert.vue?macro=true";
import { default as contractsUmkmAmE8DFMeta } from "/vercel/path0/pages/myaccount/documents/[house]/contracts.vue?macro=true";
import { default as indexRdi58Y791bMeta } from "/vercel/path0/pages/myaccount/documents/index.vue?macro=true";
import { default as menuCaHBh1HqzqMeta } from "/vercel/path0/pages/myaccount/menu.vue?macro=true";
import { default as profilerHsxKtTdTcMeta } from "/vercel/path0/pages/myaccount/profile.vue?macro=true";
import { default as calendar3LNsHRUeiCMeta } from "/vercel/path0/pages/myaccount/property/[name]/calendar.vue?macro=true";
import { default as infoUt92HZMulKMeta } from "/vercel/path0/pages/myaccount/property/[name]/info.vue?macro=true";
import { default as pricesLuMxf0hTRcMeta } from "/vercel/path0/pages/myaccount/property/[name]/prices.vue?macro=true";
import { default as rentals5u8JaXK0XLMeta } from "/vercel/path0/pages/myaccount/property/[name]/rentals.vue?macro=true";
import { default as salesTI34wKDqlZMeta } from "/vercel/path0/pages/myaccount/property/[name]/sales.vue?macro=true";
import { default as _91name_93stbuJtzND9Meta } from "/vercel/path0/pages/myaccount/property/[name].vue?macro=true";
import { default as owners7AdPskJKjyMeta } from "/vercel/path0/pages/owners.vue?macro=true";
import { default as errorV1lbm34cW1Meta } from "/vercel/path0/pages/payment/[payin]/error.vue?macro=true";
import { default as indexuGileEd63lMeta } from "/vercel/path0/pages/payment/[payin]/index.vue?macro=true";
import { default as success4K36slVHgNMeta } from "/vercel/path0/pages/payment/[payin]/success.vue?macro=true";
import { default as realEstateza57B3t7PzMeta } from "/vercel/path0/pages/realEstate.vue?macro=true";
import { default as realEstateListingsz8H81JGaDpMeta } from "/vercel/path0/pages/realEstateListings.vue?macro=true";
import { default as bookingConfirmationBSDRYzzsIzMeta } from "/vercel/path0/pages/reservationAgreement/[id]/bookingConfirmation.vue?macro=true";
import { default as clientInformation0ZT0LFmdqyMeta } from "/vercel/path0/pages/reservationAgreement/[id]/clientInformation.vue?macro=true";
import { default as identityDocumentJsy9ZklGRWMeta } from "/vercel/path0/pages/reservationAgreement/[id]/identityDocument.vue?macro=true";
import { default as reservationRequestHrPSeZfvtPMeta } from "/vercel/path0/pages/reservationAgreement/[id]/reservationRequest.vue?macro=true";
import { default as resumeClientyd4YOrQvHXMeta } from "/vercel/path0/pages/reservationAgreement/[id]/resumeClient.vue?macro=true";
import { default as resumeOwnerJUu09aXtZGMeta } from "/vercel/path0/pages/reservationAgreement/[id]/resumeOwner.vue?macro=true";
import { default as successClient0HDHJMsyPjMeta } from "/vercel/path0/pages/reservationAgreement/[id]/successClient.vue?macro=true";
import { default as successOwnerAVWyRhSjxyMeta } from "/vercel/path0/pages/reservationAgreement/[id]/successOwner.vue?macro=true";
import { default as _91id_93BhAzSqFbssMeta } from "/vercel/path0/pages/reservationAgreement/[id].vue?macro=true";
import { default as villabookGK9o6ISHp7Meta } from "/vercel/path0/pages/screenshot/villabook.vue?macro=true";
import { default as whitebrandEIvWMUb9vBMeta } from "/vercel/path0/pages/screenshot/whitebrand.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as _91token_93a7rhPWygQVMeta } from "/vercel/path0/pages/stayProposal/[token].vue?macro=true";
import { default as edit6eVjcV8dOxMeta } from "/vercel/path0/pages/stayProposal/edit.vue?macro=true";
import { default as tailorWqCVflFl9VMeta } from "/vercel/path0/pages/tailor.vue?macro=true";
import { default as testUploadFormvhxMVugON1Meta } from "/vercel/path0/pages/testUploadForm.vue?macro=true";
import { default as tgvC6j0Q5YE4UMeta } from "/vercel/path0/pages/tgv.vue?macro=true";
import { default as travelPartnersKSCj7y0c3DMeta } from "/vercel/path0/pages/travelPartners.vue?macro=true";
import { default as indexRAKcVyDKEeMeta } from "/vercel/path0/pages/typeform/index.vue?macro=true";
import { default as vallatMEnI9Xsk6tMeta } from "/vercel/path0/pages/vallat.vue?macro=true";
import { default as indexJdLb4AGsuPMeta } from "/vercel/path0/pages/wishlist/[token]/index.vue?macro=true";
import { default as sharedhPI6BAF4w7Meta } from "/vercel/path0/pages/wishlist/[token]/shared.vue?macro=true";
import { default as indexjPqwFwOe0oMeta } from "/vercel/path0/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "destination-name___fr",
    path: "/fr/:destination()/:name()",
    meta: _91name_937x63acQ0ysMeta || {},
    component: () => import("/vercel/path0/pages/[destination]/[name].vue")
  },
  {
    name: "destination-name___en",
    path: "/en/:destination()/:name()",
    meta: _91name_937x63acQ0ysMeta || {},
    component: () => import("/vercel/path0/pages/[destination]/[name].vue")
  },
  {
    name: "destination-slug-filter___fr",
    path: "/fr/:destination()/:slug()/:filter()",
    meta: _91filter_93GPpiwq3kq2Meta || {},
    component: () => import("/vercel/path0/pages/[destination]/[slug]/[filter].vue")
  },
  {
    name: "destination-slug-filter___en",
    path: "/en/:destination()/:slug()/:filter()",
    meta: _91filter_93GPpiwq3kq2Meta || {},
    component: () => import("/vercel/path0/pages/[destination]/[slug]/[filter].vue")
  },
  {
    name: "addedValue___fr",
    path: "/fr/notre-valeur-ajoutee",
    component: () => import("/vercel/path0/pages/addedValue.vue")
  },
  {
    name: "addedValue___en",
    path: "/en/our-added-value",
    component: () => import("/vercel/path0/pages/addedValue.vue")
  },
  {
    name: "bonder___fr",
    path: "/fr/bonderco",
    component: () => import("/vercel/path0/pages/bonder.vue")
  },
  {
    name: "bonder___en",
    path: "/en/bonderco",
    component: () => import("/vercel/path0/pages/bonder.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "customHolidays___fr",
    path: "/fr/vacances-sur-mesure",
    component: () => import("/vercel/path0/pages/customHolidays.vue")
  },
  {
    name: "customHolidays___en",
    path: "/en/custom-holidays",
    component: () => import("/vercel/path0/pages/customHolidays.vue")
  },
  {
    name: "events___fr",
    path: "/fr/service-evenementiel",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___en",
    path: "/en/event-service",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "feedback-id___fr",
    path: "/fr/formulaire-de-satisfaction/:id()",
    component: () => import("/vercel/path0/pages/feedback/[id].vue")
  },
  {
    name: "feedback-id___en",
    path: "/en/feedback/:id()",
    component: () => import("/vercel/path0/pages/feedback/[id].vue")
  },
  {
    name: "house-slug___fr",
    path: "/fr/location-luxe/:slug()",
    meta: _91slug_93Gxm7AbZHzHMeta || {},
    component: () => import("/vercel/path0/pages/house/[slug].vue")
  },
  {
    name: "house-slug___en",
    path: "/en/luxury-rental/:slug()",
    meta: _91slug_93Gxm7AbZHzHMeta || {},
    component: () => import("/vercel/path0/pages/house/[slug].vue")
  },
  {
    name: "house-photos___fr",
    path: "/fr/location-luxe/:slug()/photos",
    meta: photosYLJuhHW2nGMeta || {},
    component: () => import("/vercel/path0/pages/house/photos.vue")
  },
  {
    name: "house-photos___en",
    path: "/en/luxury-rental/:slug()/photos",
    meta: photosYLJuhHW2nGMeta || {},
    component: () => import("/vercel/path0/pages/house/photos.vue")
  },
  {
    name: "house-preorder-error___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option/erreur",
    meta: error0cLyEglmoSMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/error.vue")
  },
  {
    name: "house-preorder-error___en",
    path: "/en/luxury-rental/:slug()/pre-order/error",
    meta: error0cLyEglmoSMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/error.vue")
  },
  {
    name: "house-preorder___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option",
    meta: indexshYkREaB9sMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/index.vue")
  },
  {
    name: "house-preorder___en",
    path: "/en/luxury-rental/:slug()/pre-order",
    meta: indexshYkREaB9sMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/index.vue")
  },
  {
    name: "house-preorder-success___fr",
    path: "/fr/location-luxe/:slug()/pose-d-option/succes",
    meta: successUZjHTFPrCJMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/success.vue")
  },
  {
    name: "house-preorder-success___en",
    path: "/en/luxury-rental/:slug()/pre-order/success",
    meta: successUZjHTFPrCJMeta || {},
    component: () => import("/vercel/path0/pages/house/preorder/success.vue")
  },
  {
    name: "house-transaction-slug___fr",
    path: "/fr/immobilier-luxe/:slug()",
    meta: _91slug_93tzQpSEDkoLMeta || {},
    component: () => import("/vercel/path0/pages/house/transaction/[slug].vue")
  },
  {
    name: "house-transaction-slug___en",
    path: "/en/luxury-real-estate/:slug()",
    meta: _91slug_93tzQpSEDkoLMeta || {},
    component: () => import("/vercel/path0/pages/house/transaction/[slug].vue")
  },
  {
    name: "house-transaction-photos___fr",
    path: "/fr/immobilier-luxe/:slug()/photos",
    meta: photosJFQ0GlQEisMeta || {},
    component: () => import("/vercel/path0/pages/house/transaction/photos.vue")
  },
  {
    name: "house-transaction-photos___en",
    path: "/en/luxury-real-estate/:slug()/photos",
    meta: photosJFQ0GlQEisMeta || {},
    component: () => import("/vercel/path0/pages/house/transaction/photos.vue")
  },
  {
    name: "houseCollection___fr",
    path: "/fr/collection-de-maisons",
    component: () => import("/vercel/path0/pages/houseCollection.vue")
  },
  {
    name: "houseCollection___en",
    path: "/en/collection-of-houses",
    component: () => import("/vercel/path0/pages/houseCollection.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "legal-clientConditions___fr",
    path: "/fr/conditions-client",
    component: () => import("/vercel/path0/pages/legal/clientConditions.vue")
  },
  {
    name: "legal-clientConditions___en",
    path: "/en/client-conditions",
    component: () => import("/vercel/path0/pages/legal/clientConditions.vue")
  },
  {
    name: "legal-ownerConditions___fr",
    path: "/fr/conditions-proprietaire",
    component: () => import("/vercel/path0/pages/legal/ownerConditions.vue")
  },
  {
    name: "legal-ownerConditions___en",
    path: "/en/owner-conditions",
    component: () => import("/vercel/path0/pages/legal/ownerConditions.vue")
  },
  {
    name: "legal-privacyPolicy___fr",
    path: "/fr/politique-confidentialite",
    component: () => import("/vercel/path0/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-privacyPolicy___en",
    path: "/en/privacy-policy",
    component: () => import("/vercel/path0/pages/legal/privacyPolicy.vue")
  },
  {
    name: "legal-termsOfUse___fr",
    path: "/fr/conditions-generales-utilisation",
    component: () => import("/vercel/path0/pages/legal/termsOfUse.vue")
  },
  {
    name: "legal-termsOfUse___en",
    path: "/en/terms-of-use",
    component: () => import("/vercel/path0/pages/legal/termsOfUse.vue")
  },
  {
    name: "login___fr",
    path: "/fr/connexion",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "myaccount-bookings___fr",
    path: "/fr/mon-compte/reservations",
    meta: bookingsMnzJuiEuOIMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount-bookings___en",
    path: "/en/my-account/bookings",
    meta: bookingsMnzJuiEuOIMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/bookings.vue")
  },
  {
    name: "myaccount-destinationAlert___fr",
    path: "/fr/mon-compte/mes-alertes-destination",
    meta: destinationAlert6vJWuUrHLGMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/destinationAlert.vue")
  },
  {
    name: "myaccount-destinationAlert___en",
    path: "/en/my-account/my-destination-alerts",
    meta: destinationAlert6vJWuUrHLGMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/destinationAlert.vue")
  },
  {
    name: "myaccount-documents-house-contracts___fr",
    path: "/fr/mon-compte/documents/:houseId()/contrats",
    meta: contractsUmkmAmE8DFMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/documents/[house]/contracts.vue")
  },
  {
    name: "myaccount-documents-house-contracts___en",
    path: "/en/my-account/documents/:houseId()/contracts",
    meta: contractsUmkmAmE8DFMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/documents/[house]/contracts.vue")
  },
  {
    name: "myaccount-documents___fr",
    path: "/fr/mon-compte/documents",
    meta: indexRdi58Y791bMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/documents/index.vue")
  },
  {
    name: "myaccount-documents___en",
    path: "/en/my-account/documents",
    meta: indexRdi58Y791bMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/documents/index.vue")
  },
  {
    name: "myaccount-menu___fr",
    path: "/fr/mon-compte/menu",
    meta: menuCaHBh1HqzqMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/menu.vue")
  },
  {
    name: "myaccount-menu___en",
    path: "/en/my-account/menu",
    meta: menuCaHBh1HqzqMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/menu.vue")
  },
  {
    name: "myaccount-profile___fr",
    path: "/fr/mon-compte/profil",
    meta: profilerHsxKtTdTcMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/profile.vue")
  },
  {
    name: "myaccount-profile___en",
    path: "/en/my-account/profile",
    meta: profilerHsxKtTdTcMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/profile.vue")
  },
  {
    name: "myaccount-property-name___fr",
    path: "/fr/mon-compte/proprietes/:name()",
    meta: _91name_93stbuJtzND9Meta || {},
    component: () => import("/vercel/path0/pages/myaccount/property/[name].vue"),
    children: [
  {
    name: "myaccount-property-name-calendar___fr",
    path: "calendrier",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/calendar.vue")
  },
  {
    name: "myaccount-property-name-info___fr",
    path: "informations-contrat",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/info.vue")
  },
  {
    name: "myaccount-property-name-prices___fr",
    path: "prix",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/prices.vue")
  },
  {
    name: "myaccount-property-name-rentals___fr",
    path: "locations",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/rentals.vue")
  },
  {
    name: "myaccount-property-name-sales___fr",
    path: "ventes",
    meta: salesTI34wKDqlZMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/sales.vue")
  }
]
  },
  {
    name: "myaccount-property-name___en",
    path: "/en/my-account/properties/:name()",
    meta: _91name_93stbuJtzND9Meta || {},
    component: () => import("/vercel/path0/pages/myaccount/property/[name].vue"),
    children: [
  {
    name: "myaccount-property-name-calendar___en",
    path: "calendar",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/calendar.vue")
  },
  {
    name: "myaccount-property-name-info___en",
    path: "contract-information",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/info.vue")
  },
  {
    name: "myaccount-property-name-prices___en",
    path: "prices",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/prices.vue")
  },
  {
    name: "myaccount-property-name-rentals___en",
    path: "rentals",
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/rentals.vue")
  },
  {
    name: "myaccount-property-name-sales___en",
    path: "sales",
    meta: salesTI34wKDqlZMeta || {},
    component: () => import("/vercel/path0/pages/myaccount/property/[name]/sales.vue")
  }
]
  },
  {
    name: "owners___fr",
    path: "/fr/proprietaires",
    component: () => import("/vercel/path0/pages/owners.vue")
  },
  {
    name: "owners___en",
    path: "/en/owners",
    component: () => import("/vercel/path0/pages/owners.vue")
  },
  {
    name: "payment-payin-error___fr",
    path: "/fr/paiements/:payin()/erreur",
    component: () => import("/vercel/path0/pages/payment/[payin]/error.vue")
  },
  {
    name: "payment-payin-error___en",
    path: "/en/payments/:payin()/error",
    component: () => import("/vercel/path0/pages/payment/[payin]/error.vue")
  },
  {
    name: "payment-payin___fr",
    path: "/fr/paiements/:payin()",
    component: () => import("/vercel/path0/pages/payment/[payin]/index.vue")
  },
  {
    name: "payment-payin___en",
    path: "/en/payments/:payin()",
    component: () => import("/vercel/path0/pages/payment/[payin]/index.vue")
  },
  {
    name: "payment-payin-success___fr",
    path: "/fr/paiements/:payin()/succes",
    component: () => import("/vercel/path0/pages/payment/[payin]/success.vue")
  },
  {
    name: "payment-payin-success___en",
    path: "/en/payments/:payin()/success",
    component: () => import("/vercel/path0/pages/payment/[payin]/success.vue")
  },
  {
    name: "realEstate___fr",
    path: "/fr/immobilier-luxe-service",
    component: () => import("/vercel/path0/pages/realEstate.vue")
  },
  {
    name: "realEstate___en",
    path: "/en/luxury-real-estate-service",
    component: () => import("/vercel/path0/pages/realEstate.vue")
  },
  {
    name: "realEstateListings___fr",
    path: "/fr/immobilier-luxe-annonces",
    component: () => import("/vercel/path0/pages/realEstateListings.vue")
  },
  {
    name: "realEstateListings___en",
    path: "/en/luxury-real-estate-listing",
    component: () => import("/vercel/path0/pages/realEstateListings.vue")
  },
  {
    name: "reservationAgreement-id___fr",
    path: "/fr/reservationAgreement/:id()",
    meta: _91id_93BhAzSqFbssMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id].vue"),
    children: [
  {
    name: "reservationAgreement-id-bookingConfirmation___fr",
    path: "/fr/contrat-de-location/:id()/confirmer",
    meta: bookingConfirmationBSDRYzzsIzMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/bookingConfirmation.vue")
  },
  {
    name: "reservationAgreement-id-clientInformation___fr",
    path: "/fr/contrat-de-location/:id()/information-client",
    meta: clientInformation0ZT0LFmdqyMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/clientInformation.vue")
  },
  {
    name: "reservationAgreement-id-identityDocument___fr",
    path: "/fr/contrat-de-location/:id()/document-identite",
    meta: identityDocumentJsy9ZklGRWMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/identityDocument.vue")
  },
  {
    name: "reservationAgreement-id-reservationRequest___fr",
    path: "/fr/demande-de-reservation/:id()/confirmer",
    meta: reservationRequestHrPSeZfvtPMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/reservationRequest.vue")
  },
  {
    name: "reservationAgreement-id-resumeClient___fr",
    path: "/fr/contrat-de-location/:id()/recapitulatif",
    meta: resumeClientyd4YOrQvHXMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/resumeClient.vue")
  },
  {
    name: "reservationAgreement-id-resumeOwner___fr",
    path: "/fr/demande-de-reservation/:id()/recapitulatif",
    meta: resumeOwnerJUu09aXtZGMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/resumeOwner.vue")
  },
  {
    name: "reservationAgreement-id-successClient___fr",
    path: "/fr/contrat-de-location/:id()/paiement",
    meta: successClient0HDHJMsyPjMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/successClient.vue")
  },
  {
    name: "reservationAgreement-id-successOwner___fr",
    path: "/fr/demande-de-reservation/:id()/accepter",
    meta: successOwnerAVWyRhSjxyMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/successOwner.vue")
  }
]
  },
  {
    name: "reservationAgreement-id___en",
    path: "/en/reservationAgreement/:id()",
    meta: _91id_93BhAzSqFbssMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id].vue"),
    children: [
  {
    name: "reservationAgreement-id-bookingConfirmation___en",
    path: "/en/rental-contract/:id()/confirmation",
    meta: bookingConfirmationBSDRYzzsIzMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/bookingConfirmation.vue")
  },
  {
    name: "reservationAgreement-id-clientInformation___en",
    path: "/en/rental-contract/:id()/client-information",
    meta: clientInformation0ZT0LFmdqyMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/clientInformation.vue")
  },
  {
    name: "reservationAgreement-id-identityDocument___en",
    path: "/en/rental-contract/:id()/identity-documents",
    meta: identityDocumentJsy9ZklGRWMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/identityDocument.vue")
  },
  {
    name: "reservationAgreement-id-reservationRequest___en",
    path: "/en/reservation-request/:id()/confirm",
    meta: reservationRequestHrPSeZfvtPMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/reservationRequest.vue")
  },
  {
    name: "reservationAgreement-id-resumeClient___en",
    path: "/en/rental-contract/:id()/resume",
    meta: resumeClientyd4YOrQvHXMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/resumeClient.vue")
  },
  {
    name: "reservationAgreement-id-resumeOwner___en",
    path: "/en/reservation-request/:id()/resume",
    meta: resumeOwnerJUu09aXtZGMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/resumeOwner.vue")
  },
  {
    name: "reservationAgreement-id-successClient___en",
    path: "/en/rental-contract/:id()/payment",
    meta: successClient0HDHJMsyPjMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/successClient.vue")
  },
  {
    name: "reservationAgreement-id-successOwner___en",
    path: "/en/reservation-request/:id()/accept-client",
    meta: successOwnerAVWyRhSjxyMeta || {},
    component: () => import("/vercel/path0/pages/reservationAgreement/[id]/successOwner.vue")
  }
]
  },
  {
    name: "screenshot-villabook___fr",
    path: "/fr/villabook/:id()",
    meta: villabookGK9o6ISHp7Meta || {},
    component: () => import("/vercel/path0/pages/screenshot/villabook.vue")
  },
  {
    name: "screenshot-villabook___en",
    path: "/en/villabook/:id()",
    meta: villabookGK9o6ISHp7Meta || {},
    component: () => import("/vercel/path0/pages/screenshot/villabook.vue")
  },
  {
    name: "screenshot-whitebrand___fr",
    path: "/fr/preview/:id()",
    meta: whitebrandEIvWMUb9vBMeta || {},
    component: () => import("/vercel/path0/pages/screenshot/whitebrand.vue")
  },
  {
    name: "screenshot-whitebrand___en",
    path: "/en/preview/:id()",
    meta: whitebrandEIvWMUb9vBMeta || {},
    component: () => import("/vercel/path0/pages/screenshot/whitebrand.vue")
  },
  {
    name: "search___fr",
    path: "/fr/recherche",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/plan-du-site",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___en",
    path: "/en/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "stayProposal-token___fr",
    path: "/fr/proposition-de-sejour/:token()",
    meta: _91token_93a7rhPWygQVMeta || {},
    component: () => import("/vercel/path0/pages/stayProposal/[token].vue")
  },
  {
    name: "stayProposal-token___en",
    path: "/en/stay-proposal/:token()",
    meta: _91token_93a7rhPWygQVMeta || {},
    component: () => import("/vercel/path0/pages/stayProposal/[token].vue")
  },
  {
    name: "stayProposal-edit___fr",
    path: "/fr/modifier-proposition-de-sejour",
    meta: edit6eVjcV8dOxMeta || {},
    component: () => import("/vercel/path0/pages/stayProposal/edit.vue")
  },
  {
    name: "stayProposal-edit___en",
    path: "/en/edit-stay-proposal",
    meta: edit6eVjcV8dOxMeta || {},
    component: () => import("/vercel/path0/pages/stayProposal/edit.vue")
  },
  {
    name: "tailor___fr",
    path: "/fr/notre-conciergerie-de-luxe",
    component: () => import("/vercel/path0/pages/tailor.vue")
  },
  {
    name: "tailor___en",
    path: "/en/our-luxury-concierge-service",
    component: () => import("/vercel/path0/pages/tailor.vue")
  },
  {
    name: "testUploadForm___fr",
    path: "/fr/test-formulaire-upload",
    component: () => import("/vercel/path0/pages/testUploadForm.vue")
  },
  {
    name: "testUploadForm___en",
    path: "/en/test-upload-form",
    component: () => import("/vercel/path0/pages/testUploadForm.vue")
  },
  {
    name: "tgv___fr",
    path: "/fr/the-greek-villas",
    component: () => import("/vercel/path0/pages/tgv.vue")
  },
  {
    name: "tgv___en",
    path: "/en/the-greek-villas",
    component: () => import("/vercel/path0/pages/tgv.vue")
  },
  {
    name: "travelPartners___fr",
    path: "/fr/agences-partenaires",
    component: () => import("/vercel/path0/pages/travelPartners.vue")
  },
  {
    name: "travelPartners___en",
    path: "/en/travel-partners",
    component: () => import("/vercel/path0/pages/travelPartners.vue")
  },
  {
    name: "typeform___fr",
    path: "/fr/demande-sur-mesure",
    component: () => import("/vercel/path0/pages/typeform/index.vue")
  },
  {
    name: "typeform___en",
    path: "/en/tailor-made-holiday",
    component: () => import("/vercel/path0/pages/typeform/index.vue")
  },
  {
    name: "vallat___fr",
    path: "/fr/vallat",
    component: () => import("/vercel/path0/pages/vallat.vue")
  },
  {
    name: "vallat___en",
    path: "/en/vallat",
    component: () => import("/vercel/path0/pages/vallat.vue")
  },
  {
    name: "wishlist-token___fr",
    path: "/fr/wishlist/:token()",
    meta: indexJdLb4AGsuPMeta || {},
    component: () => import("/vercel/path0/pages/wishlist/[token]/index.vue")
  },
  {
    name: "wishlist-token___en",
    path: "/en/wishlist/:token()",
    meta: indexJdLb4AGsuPMeta || {},
    component: () => import("/vercel/path0/pages/wishlist/[token]/index.vue")
  },
  {
    name: "wishlist-token-shared___fr",
    path: "/fr/wishlist/:token()/partage",
    meta: sharedhPI6BAF4w7Meta || {},
    component: () => import("/vercel/path0/pages/wishlist/[token]/shared.vue")
  },
  {
    name: "wishlist-token-shared___en",
    path: "/en/wishlist/:token()/shared",
    meta: sharedhPI6BAF4w7Meta || {},
    component: () => import("/vercel/path0/pages/wishlist/[token]/shared.vue")
  },
  {
    name: "wishlist___fr",
    path: "/fr/wishlist",
    meta: indexjPqwFwOe0oMeta || {},
    component: () => import("/vercel/path0/pages/wishlist/index.vue")
  },
  {
    name: "wishlist___en",
    path: "/en/wishlist",
    meta: indexjPqwFwOe0oMeta || {},
    component: () => import("/vercel/path0/pages/wishlist/index.vue")
  }
]