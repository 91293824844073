<script setup lang="ts">
import { useLockBody } from '~/composables/useLockBody'
import type { RouteLocation } from 'vue-router'

const router = useRouter()
const { headerMobile, setSearchIsOpen } = useHeaderMobile()
const { setTemporaryTheme } = useHeaderNavigation()

const menuIsOpen = computed(() => headerMobile.value.menuIsOpen)
const searchIsOpen = computed(() => headerMobile.value.searchIsOpen)

watch(
  () => searchIsOpen.value,
  (val) => {
    if (!menuIsOpen.value) {
      useLockBody(val)
    }
  },
)

const closeSearchMenu = () => {
  setSearchIsOpen(false)
  setTemporaryTheme('')
}
const closeSearchMenuAndRedirect = (route: RouteLocation) => {
  closeSearchMenu()
  router.push(route)
}
</script>

<template>
  <transition name="fade" class="the-menu-mobile-search-wrap">
    <div
      v-show="searchIsOpen"
      class="the-menu-mobile-search"
      data-testid="the-menu-mobile-search"
    >
      <div
        v-show="searchIsOpen"
        class="the-menu-mobile-search-container"
        data-testid="the-menu-mobile-search-container"
      >
        <the-header-search
          class="grow"
          @link-clicked="closeSearchMenuAndRedirect"
        />

        <div class="mt-10 flex justify-center">
          <the-header-close-button @close="closeSearchMenu" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style>
.the-menu-mobile-search {
  @apply opacity-100 fixed top-0 left-0 h-full w-full z-[1040] transition-all duration-500 ease-in-out overflow-hidden bg-white;
}

.the-menu-mobile-search-container {
  @apply overflow-y-scroll absolute left-0 w-full flex flex-col pt-6 pb-10 px-4;
  top: var(--header-height);
  height: calc(100% - var(--header-height));
}

.the-menu-mobile-search-wrap.fade-enter-active,
.the-menu-mobile-search-wrap.fade-leave-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.the-menu-mobile-search-wrap.fade-enter,
.the-menu-mobile-search-wrap.fade-leave-to {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
</style>
