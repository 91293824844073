<script setup lang="ts">
type LinkItem = {
  id: number
  label?: string
  type?: string
  path: string
  name: string
  external_link?: boolean
  nofollow?: boolean
}
withDefaults(
  defineProps<{
    title: string
    list?: LinkItem[]
  }>(),
  {
    list: () => [],
  },
)
defineEmits<{ 'track-event': [] }>()
defineSlots<{ default(): any }>()

const rel = (item: LinkItem) => {
  if (item.nofollow && item.external_link) {
    return 'nofollow noopener'
  } else if (item.nofollow) {
    return 'nofollow'
  } else if (item.external_link) {
    return 'noopener'
  }
}
</script>

<template>
  <BaseCol>
    <div class="m-0 mb-6 font-sansSerif text-base font-medium text-primary-500">
      {{ title }}
    </div>
    <ul class="footer__list">
      <li v-for="item in list" :key="item.id">
        <a
          v-if="item.type === 'button'"
          :href="item.path"
          class="footer__list-link text-small"
        >
          {{ item.name }}
        </a>

        <NuxtLink
          v-else
          :to="item.path"
          :target="item.external_link ? '_blank' : ''"
          :rel="rel(item)"
          class="footer__list-link text-small"
        >
          {{ item.name }}
        </NuxtLink>
      </li>
      <slot />
    </ul>
  </BaseCol>
</template>

<style>
.footer__list {
  @apply pl-0;
  list-style: none;
  margin: 0 0 3rem 0;
}

@screen sm {
  .footer__list {
    margin: 0 0 1rem 0;
  }
}

.footer__list-link {
  @apply text-gray-200;
}
.footer__list-link:hover {
  @apply text-white no-underline;
}
</style>
