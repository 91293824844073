import type { SupportedCurrencies } from 'lc-services/types'
import type { UserInput } from '~/repository/entities/user'
import type { User } from 'lc-repository/dist/entities'
type UserSerialize = User<{ serialized: true }>

export const useUser = () => {
  const { $lcRepositories } = useNuxtApp()

  const USER_INFORMATIONS_DEFAULT = {
    address: null,
    bankAccountOwner: '',
    bic: '',
    birthdate: null,
    city: null,
    civility: null,
    clientGeneralRentalConditionSigned: false,
    companiesIds: [],
    confirmedAt: '',
    country: null,
    createdAt: '',
    devicesIds: [],
    email: '',
    esClientGeneralRentalConditionSignedAt: null,
    firstName: null,
    frClientGeneralRentalConditionSignedAt: null,
    hasAccessibleHouses: false,
    hasCompanies: false,
    hasHouses: false,
    housesIds: [],
    iban: '',
    id: '',
    identityDocumentExpirationDate: null,
    identityDocumentNumber: null,
    identityDocumentType: null,
    incompleteSignup: false,
    invitationAcceptedAt: null,
    invitationCreatedAt: null,
    invitationSentAt: null,
    isAdmin: false,
    lastName: null,
    locale: '',
    nationality: null,
    newsletterSubscribed: false,
    ownerGeneralRentalConditionSigned: false,
    partnershipAgency: false,
    partnershipAgencyName: '',
    phone: null,
    postalCode: null,
    ribFile: '',
    secondaryEmail: null,
    secondaryPhone: null,
    termsOfServiceSigned: false,
    termsOfServiceSignedAt: null,
    travelAgentGeneralRentalConditionSignedAt: null,
    updatedAt: '',
  }

  const userState = useState<{
    userInformations: UserSerialize
    __UNSAVED__userInformations: {
      currency: SupportedCurrencies
    }
  }>('state-user', () => ({
    userInformations: { ...USER_INFORMATIONS_DEFAULT },
    __UNSAVED__userInformations: {
      currency: 'EUR',
    },
  }))

  const user = computed(() => userState.value.userInformations)
  const __UNSAVED__userInformations = computed(
    () => userState.value.__UNSAVED__userInformations,
  )
  const currency = computed(() => __UNSAVED__userInformations.value.currency)
  const hasMobileApp = computed(() => Boolean(user.value.devicesIds.length))
  const isOwner = computed(() => Boolean(user.value.housesIds.length))
  const userType = computed(() => {
    if (!user.value) return 'visitor'
    if (user.value.isAdmin) return 'admin'
    if (user.value.partnershipAgency) return 'agent'
    if (isOwner.value) return 'owner'
    if (user.value.id && user.value.incompleteSignup === false) return 'user'
    return 'visitor'
  })

  const fetchUserInformations = async () => {
    try {
      const res = await $lcRepositories.user.information.getUserInfo()
      if (res?.data) userState.value.userInformations = res.data
    } catch {
      clearUser()
    }
  }

  const modifyUserInformations = async (user: UserInput) => {
    const res = await $lcRepositories.user.information.modifyUserInfo(user)
    if (res?.data) userState.value.userInformations = res.data
  }

  const clearUser = () => {
    userState.value.userInformations = USER_INFORMATIONS_DEFAULT
  }

  const setCurrency = (currency: SupportedCurrencies) => {
    userState.value.__UNSAVED__userInformations.currency = currency
  }

  return {
    clearUser,
    currency,
    fetchUserInformations,
    hasMobileApp,
    isOwner,
    modifyUserInformations,
    setCurrency,
    user,
    userType,
  }
}
