
import * as cloudimageRuntime$ZPKfp4m8xE from '/vercel/path0/node_modules/.pnpm/@nuxt+image-edge@1.3.0-28493895.369b747_@upstash+redis@1.34.0_@vercel+kv@3.0.0_ioredis@5.4.1__pydw4hhft3vq3g7u2q47sygl7m/node_modules/@nuxt/image-edge/dist/runtime/providers/cloudimage'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1170,
    "xxl": 1440,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudimage",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 65
}

imageOptions.providers = {
  ['cloudimage']: { provider: cloudimageRuntime$ZPKfp4m8xE, defaults: {"baseURL":"__collectionist__","cdnURL":"https://cdn.lecollectionist.com","format":["webp"]} }
}
        