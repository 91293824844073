import type { Auth } from '~/types/types'

export default defineNuxtRouteMiddleware(async () => {
  const { $api, $httpCookies } = useNuxtApp()
  const { authenticated, login, logout } = useAuth()

  const uid = $httpCookies.get('uid')
  const client = $httpCookies.get('client')
  const accessToken = $httpCookies.get('access-token')

  try {
    if (!accessToken || !client || !uid) throw new Error('no credentials')
    $api.setHeaders({
      'access-token': accessToken,
      client,
      uid,
    })

    const { data: user } = await useAsyncData<
      { data: Auth['user'] },
      unknown,
      Auth['user']
    >('validateToken', () => $api.v1.auth.validateToken(), {
      transform: (res) => res.data,
    })

    if (user.value && user.value.incompleteSignup === false) {
      if (!authenticated.value) {
        await login(user.value)
      }
      if (user.value.algoliaAdminSearchKey) {
        $httpCookies.set('search_admin_token', user.value.algoliaAdminSearchKey)
      }
      if (user.value.algoliaB2b2cSearchKey) {
        $httpCookies.set('search_b2b2c_token', user.value.algoliaB2b2cSearchKey)
      }
      $httpCookies.set('user_wishlist_token', user.value.wishlistToken)
    }
  } catch {
    await logout()
  }
})
