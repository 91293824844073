<script setup lang="ts">
const localePath = useLocalePath()
const { t } = useI18n()
const { isMobile } = useDevice()
const { createWishlist, updateWishlist, setLastWishlistTokenActive } =
  useMultipleWishlist()
const { setSummaryOpen } = useMultipleWishlistSummary()
const { trackEvent } = useTracking()
const { createNotification } = useNotifications()

const emits = defineEmits<{
  'wishlist-created': []
  'wishlist-updated': [title: string]
}>()

const props = withDefaults(
  defineProps<{
    edition?: boolean
    edtionToken?: string
    houseIds?: number[]
    houseName?: string
    initialTitle?: string
    title: string
  }>(),
  {
    edition: false,
    edtionToken: '',
    houseIds: () => [],
    houseName: '',
    initialTitle: '',
  },
)

defineSlots<{ banner(): any }>()

const maxCharacters = 30
const titleInput = ref(props.initialTitle || '')
const disabledButton = ref(false)

const message = computed(
  () =>
    `${titleInput.value?.length}/${maxCharacters} ${t(
      'wishlistMultiple.characters',
    )}`,
)

const handleSubmit = () => {
  disabledButton.value = true
  if (props.edition) handleUpdateWishlist()
  else handleCreateWishlist()
}

const handleCreateWishlist = async () => {
  const payload = { title: titleInput.value, house_ids: props.houseIds }

  const wishlistCreated = await createWishlist(payload)

  if (wishlistCreated?.title && props.houseName) {
    setLastWishlistTokenActive(wishlistCreated.privateToken)

    const isTreatment = useExperimentCondition('multi-inquiry-from-search')
      .value.isTreatment

    const link = isMobile
      ? {
          title: t('wishlistMultiple.seeTheList'),
          to: !isTreatment
            ? localePath({
                name: 'wishlist-token',
                params: { token: wishlistCreated.privateToken },
              })
            : undefined,
          fn: isTreatment
            ? () => {
                setSummaryOpen(true)
              }
            : undefined,
        }
      : null

    createNotification({
      link,
      message: t('wishlistMultiple.addedTo', {
        houseName: props.houseName,
        listName: wishlistCreated.title,
      }),
      showCloseButton: false,
      type: 'secondary',
    })
  }

  disabledButton.value = false

  trackEvent({
    event: 'wishlist_house_added',
    category: 'wishlist',
    wishlist_id: wishlistCreated?.privateToken,
    product: {
      house_id: props.houseIds[0],
      house_name: props.houseName,
    },
  })
  emits('wishlist-created')
}

const handleUpdateWishlist = async () => {
  const payload = {
    title: titleInput.value,
    wishlistToken: props.edtionToken,
  }

  const wishlistUpdated = await updateWishlist(payload)

  if (wishlistUpdated?.title) {
    createNotification({
      message: t('wishlistMultiple.wishlistUpdated', {
        title: wishlistUpdated.title,
      }),
      showCloseButton: false,
      type: 'secondary',
    })

    disabledButton.value = false
    emits('wishlist-updated', wishlistUpdated.title)
  }
}
</script>

<template>
  <div>
    <div class="mb-6 text-xl font-bold">
      {{ title }}
    </div>

    <slot name="banner" />

    <BaseFormComponentsBaseInput
      v-model="titleInput"
      :info-text="message"
      :label="$t('wishlistMultiple.nameOfTheList')"
      :maxlength="maxCharacters"
      data-cy="title"
      id-form="title"
      name="title"
    />

    <div class="flex justify-end">
      <BaseButton
        :disabled="!titleInput.length || disabledButton"
        class="w-full lg:w-auto"
        color="secondary"
        data-cy="validate-wishlist"
        @click="handleSubmit"
      >
        {{ t('global.validate') }}
      </BaseButton>
    </div>
  </div>
</template>
