export const useUserProperties = () => {
  const { $lcServicesUserProperties } = useNuxtApp()
  const { locale } = useI18n()
  const { userPropertiesController, userPropertiesPresenter } =
    $lcServicesUserProperties

  const userProperties = useState('user-properties')
  const isLoading = ref(false)

  const fetchUserPropertiesByLocation = async () => {
    isLoading.value = true
    try {
      await userPropertiesController.getUserPropertiesByLocation({
        locale: locale.value,
      })
      userProperties.value = userPropertiesPresenter.vm.userProperties
    } catch (error) {
      console.error('Failed to fetch user properties:', error)
    } finally {
      isLoading.value = false
    }
    return userProperties.value
  }
  const userSpecificProperties = computed(() => userProperties.value)
  return {
    fetchUserPropertiesByLocation,
    userSpecificProperties,
    isLoading,
  }
}
